import outdatedBrowser from 'outdated-browser-rework';

outdatedBrowser({
  browserSupport: {
    Chrome: 57, // Includes Chrome for mobile devices
    Edge: 39,
    Safari: 10,
    'Mobile Safari': 10,
    Firefox: 50,
    Opera: 50,
    Vivaldi: 1,
    // You could specify minor version too for those browsers that need it.
    Yandex: { major: 17, minor: 10 },
    IE: false,
  },
  requireChromeOnAndroid: false,
  isUnknownBrowserOK: true,
  messages: {
    en: {
      outOfDate: 'Deze browser wordt niet ondersteund',
      unsupported: 'Deze browser wordt niet ondersteund',
      update: {
        web: 'Het identificatieproces werkt uitsluitend op de browsers Chrome, Explorer, Safari, Edge en Mozilla Firefox. Lees meer hierover in onze FAQ.',
        googlePlay:
          'Het identificatieproces werkt uitsluitend op de browsers Chrome, Explorer, Safari, Edge en Mozilla Firefox. Lees meer hierover in onze FAQ.',
        appStore:
          'Het identificatieproces werkt uitsluitend op de browsers Chrome, Explorer, Safari, Edge en Mozilla Firefox. Lees meer hierover in onze FAQ.',
      },
      url: null,
      callToAction: 'Update my browser now',
      close: 'Close',
    },
  },
});
